import React, {Component} from "react";

import "./spinner.css";

export default class Spinner extends Component {
  
  render() {
    return (
      <div className="spinner">
        <div className="loadingio-spinner-magnify-nn1fehrbzvm">
          <div className="ldio-81fss5q5czi">
<div><div><div></div><div></div></div></div>
</div></div>
      </div>
      
    );
  }
}