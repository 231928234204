import React, {Suspense, useEffect, useState, useContext} from 'react';
import Header from './components/header';
import NavMob from './components/nav-mob';
// import LkPage from './pages/lk-page';
// import NewsPage from './pages/news-page';
// import ProglistPage from './pages/proglist-page';
// import ProgAdd from './components/prog-add';
// import Prog from './components/prog';

import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
// import LocalActsPage from './components/local-acts-page';
// import HolidaysPage from './components/holidays-page';
// import TeachersListPage from './components/teachers-list-page';
// import LoginPage from './components/login-page';
import {CurrentUserContext} from './components/contexts/currentUser';

import useLocalStorage from './hooks/useLocalStorage';
import {ProtectedRoute} from './components/protectedRoute';
import {ProtectedComponent} from './components/protectedComponent';
// import LogoutPage from './pages/logout/logout-page';
import Tapi from './services/tapi';
import Spinner from './components/spinner';

const LocalActsPage = React.lazy(() => import('./components/local-acts-page'));
const HolidaysPage = React.lazy(() => import('./components/holidays-page'));
const TeachersListPage = React.lazy(() =>
  import('./components/teachers-list-page')
);
const LoginPage = React.lazy(() => import('./components/login-page'));
const LogoutPage = React.lazy(() => import('./pages/logout'));
const ProglistPage = React.lazy(() => import('./pages/proglist-page'));
const NewsPage = React.lazy(() => import('./pages/news-page'));
const ProgAdd = React.lazy(() => import('./components/prog-add'));
const Prog = React.lazy(() => import('./components/prog'));
const LkPage = React.lazy(() => import('./pages/lk-page'));

const Routes = () => {
  const [, setCurrentUserState] = useContext(CurrentUserContext);
  const [token] = useLocalStorage('token');
  const [tid] = useLocalStorage('tid');

  useEffect(async () => {
    let teacher;
    if (tid) {
      const {getOneTeacher} = new Tapi();
      teacher = await getOneTeacher(tid);
    }
    setCurrentUserState((state) => ({
      ...state,
      isLoggedIn: Boolean(token),
      isLoading: false,
      currentUser: {
        token: token ? token : '',
        tid: tid ? tid : '',
      },
      admin: Boolean(token) && !Boolean(tid),
      teacher: tid ? teacher : null,
    }));
  }, [token, tid]);

  // setCurrentUserState((state) => ({
  //   ...state,
  //   isLoggedIn: Boolean(token),
  //   isLoading: false,
  //   /* currentUser: Boolean(token)
  //     ? Boolean(tid)
  //       ? {tid: tid, token: token}
  //       : {token: token}
  //     : null, */
  //   admin: Boolean(token) && !Boolean(tid),
  // }));

  return (
    <React.Fragment>
      <Router>
        {/* <Suspense fallback={<div>Загрузон...</div>}> */}
        <Suspense fallback={<Spinner />}>
          <ProtectedComponent component={Header} />
          <Route path="/login" exact component={LoginPage} />

          {/* <Route
        path="/"
        render={({match, location, history}) =>
          isLoggedIn ? (
            <Header isLoggedIn={isLoggedIn} location={location.pathname} />
          ) : null
        }
      /> */}

          <ProtectedRoute path="/" exact component={NewsPage} />
          <ProtectedRoute path="/news" exact component={NewsPage} />

          <ProtectedRoute path="/lk/:id?" component={LkPage} />

          <ProtectedRoute path="/proglist" exact component={ProglistPage} />

          <ProtectedRoute
            path="/proglist/:id"
            component={Prog}
            /* render={({match, location, history}) => {
            const {id} = match.params;
            return <Prog itemId={id} />;
          }} */
          />

          <ProtectedRoute path="/add-prog" component={ProgAdd} />

          <ProtectedRoute
            path="/local-acts"
            component={LocalActsPage}
            /* render={() => (
            <LocalActsPage admin={admin} title={TITLE.local_acts} />
          )} */
          />
          <ProtectedRoute path="/holidays" component={HolidaysPage} />
          <ProtectedRoute
            path="/teachers"
            component={TeachersListPage}
            /* render={() => (
            <TeachersListPage admin={admin} title={TITLE.teachers} />
          )} */
          />

          {/*  
        <ProtectedRoute
          path="/lk/:id?"
          render={() => <LkPage admin={admin} isLoggedIn={isLoggedIn} />}
        />

        <ProtectedRoute
          path="/news"
          render={() => (
            <NewsPage
              admin={admin}
              title={TITLE.news}
              isLoggedIn={isLoggedIn}
            />
          )}
        />

        <ProtectedRoute
          path="/proglist"
          exact
          render={() => {
            return (
              <ProglistPage
                admin={admin}
                title={TITLE.proglist}
                isLoggedIn={isLoggedIn}
              />
            );
          }}
        />

        <ProtectedRoute
          path="/proglist/:id"
          render={({match, location, history}) => {
            const {id} = match.params;
            return <Prog itemId={id} />;
          }}
        />

        <ProtectedRoute
          path="/add-prog"
          render={() => <ProgAdd title={TITLE.progadd} />}
        />

        <ProtectedRoute
          path="/local-acts"
          render={() => (
            <LocalActsPage admin={admin} title={TITLE.local_acts} />
          )}
        />

        <ProtectedRoute
          path="/holidays"
          render={() => <HolidaysPage admin={admin} title={TITLE.holidays} />}
        />
        <ProtectedRoute
          path="/teachers"
          render={() => (
            <TeachersListPage admin={admin} title={TITLE.teachers} />
          )}
        /> */}
          {/* 
      <Route
        path="/"
        render={({location}) =>
          isLoggedIn ? (
            <NavMob isLoggedIn={isLoggedIn} location={location.pathname} />
          ) : null
        }
      /> */}
          <ProtectedComponent component={NavMob} />
          <Route path="/logout" exact component={LogoutPage} />
        </Suspense>
      </Router>
    </React.Fragment>
  );
  // }
};
export default Routes;
