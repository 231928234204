import React from 'react';
import BigErrorBoundary from '../big-error-boundary';

import './app.css';

import {CurrentUserProvider} from '../contexts/currentUser';
import Routes from '../../routes';

const App = () => {
  return (
    <BigErrorBoundary>
      <CurrentUserProvider>
        <Routes />
      </CurrentUserProvider>
    </BigErrorBoundary>
  );
  // }
};
export default App;
