const getTeacherFio = (name, sname, fname) => {
  return `${name.slice(0, 1)}. ${sname.slice(0, 1)}. ${fname}`;
};
const getTeacherModifiedProg = (prog) => {
  console.log('getTeacherModifiedProgs', prog);
  return `${prog.prog_code}-${prog.abbr}`;
};
const getMultiSelectValues = (select) => {
  var result = [];
  var options = select && select.options;
  var opt;

  for (var i = 0, iLen = options.length; i < iLen; i++) {
    opt = options[i];

    if (opt.selected) {
      result.push(opt.value || opt.text);
    }
  }
  return result;
};
const fromMillisecToDate = (msec) => {
  const regExp = /\s([\d]{2})\s([\d]{4})(\s[\d:]{8}\s)/;
  let dateO = new Date(msec * 1000);
  // console.log('dateOooooooooooo', dateO);
  let fullDate = dateO.toString().match(regExp);
  const time = fullDate[3];
  const date = `${fullDate[2]}-${('0' + (dateO.getMonth() + 1)).slice(-2)}-${
    fullDate[1]
  } ${time.trim()}`;

  return date;
};
const fromMillisecToDateRus = (date) => {
  return new Date(+(date * 1000)).toLocaleString('ru-RU', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};

const getCreated = () => {
  return fromMillisecToDate(Date.now() / 1000);
};
const serverDateToFullDate = (dateStr) => {
  const regExp = /(^[\d-]{10})[\s|T]([\d:]{8})/;
  const fullDate = dateStr.match(regExp);
  return fullDate;
};
const serverDateToClientDate = (dateStr) => {};
const dateReverse = (date) => {
  return date.split('-').reverse().join('.');
};
const modifyBirthdate = (birthdate) => {
  if (/0{4}(-0{2}){2}/.test(birthdate)) {
    birthdate = '';
  }
  return birthdate;
};

const getFirstWordConjunction = (str) => {
  const arr = str.split(' ');
  const voyelles = ['a', 'e', 'ё', 'и', 'о', 'у', 'э', 'ю', 'я'];
  const lastSym = arr[0].slice(-1);
  console.log(lastSym);
  const firstWord = voyelles.includes(lastSym) ? arr[0].slice(0, -1) : arr[0];
  const contactName = firstWord + 'у ' + arr.splice(1).join(' ');
  return contactName;
};
const getIndexOfList = (target, itemSelector, parentSelector) => {
  const tr = target.closest(itemSelector);
  console.log(tr);
  const rows = [].slice.call(
    target.closest(parentSelector).querySelectorAll(itemSelector)
  );
  const index = rows.indexOf(tr);
  return index;
};

export {
  getTeacherFio,
  fromMillisecToDate,
  fromMillisecToDateRus,
  getCreated,
  serverDateToFullDate,
  dateReverse,
  serverDateToClientDate,
  getMultiSelectValues,
  getTeacherModifiedProg,
  modifyBirthdate,
  getFirstWordConjunction,
  getIndexOfList,
};
