import {
  serverDateToFullDate,
  fromMillisecToDate,
  fromMillisecToDateRus,
  dateReverse,
} from '../utils';
import authHeader from './auth-header';

export default class Tapi {
  _apiBase = 'https://ci.prorisunok.ru/jsontrees/'; // https://ci.prorisunok.ru //http://ci.prorisunok.ru/jsontrees/   // http://localhost:8010/proxy/jsontrees/
  _teacherImageBase = '';
  _docBase = 'https://ci.prorisunok.ru/mediafiles';

  getResource = async (url) => {
    // console.log('authHeader()', authHeader());
    const res = await fetch(this._apiBase + url, {headers: authHeader()});
    if (!res.ok) {
      // throw new Error(`Could not fetch ${url}` + `, received ${res.status}`);
      console.error(`Could not fetch ${url}` + `, received ${res.status}`);
      return {errorServer: res.status, result: res.json()};
    }
    return await res.json();
  };
  _sortNewsByDateFixed = (newslist) => {
    let fixedArr = [],
      list = newslist.slice();
    // fixedArr = list
    for (let i = list.length - 1; i >= 0; i--) {
      if (list[i]['fixed']) {
        fixedArr.push(list.splice(i, 1)[0]);
      }
    }
    fixedArr = fixedArr.slice().sort((a, b) => b.fullDate - a.fullDate);
    list = list.slice().sort((a, b) => b.fullDate - a.fullDate);
    const res = [...fixedArr, ...list];
    return res;
  };
  getAllNews = async () => {
    const res = await this.getResource('news');
    const result = res.results.map(this._transformNews);
    // console.log(this._sortNewsByDateFixed(result));
    return this._sortNewsByDateFixed(result);
  };

  getOneNews = async (id) => {
    const news = await this.getResource(`news/${id}`);
    return this._transformNews(news);
  };
  getAllTeachers = async () => {
    const res = await this.getResource('teachers');
    return res.results.map(this._transformTeacher);
  };

  getOneTeacher = async (id) => {
    const teacher = await this.getResource(`teachers/${id}`);
    await console.log(teacher);
    return this._transformTeacher(teacher);
  };
  _transformTeacher = (teacher) => {
    return teacher;
  };
  getAllProgs = async () => {
    const res = await this.getResource('progs');
    // console.log(res);
    return res.results.map((prog, i) => {
      return this._transformProg(prog);
    });
  };
  getOneProg = async (code) => {
    const prog = await this.getResource(`progs/${code}`);
    const docs = await this.getProgDocs(code);
    const comments = await this.getProgComments(code);
    const allTeachers = await this.getAllTeachers();
    const progTeacherList = await this.getProgTeacherList(code);
    // return this._transformProg(prog);
    return this._transformProg(
      prog,
      docs,
      comments,
      allTeachers,
      progTeacherList
    );
  };

  _transformProg = (
    prog,
    docs = 0,
    comments = 0,
    allTeachers = 0,
    progTeacherList = 0
  ) => {
    // console.log(allTeachers);
    return {
      id: prog.id,
      code: prog.code,
      abbr: prog.abbr,
      title: prog.name,
      age: prog.age,
      duration: prog.duration,
      duration_hours: prog.duration_hours,
      author: prog.author_teacher_id,
      closed: Boolean(+prog.closed),
      docs: docs === 0 ? null : docs['error'] ? [] : docs,
      comments: comments === 0 ? null : comments['error'] ? [] : comments,
      allTeachers,
      progTeacherList,
    };
  };
  _transformNews = (news) => {
    const regExp = /(^[\d-]{10})[\s|T]([\d:]{8})/;
    // const str = '2014-12-20T10:57:57';
    const fullDate = news.date.match(regExp);
    // console.log(new Date(news.date));
    return {
      id: news.id,
      title: news.title,
      fullDate: new Date(news.date).getTime(),
      date: fullDate[1].replace(/-/g, '.'),
      time: fullDate[2],
      body: news.body,
      fixed: Boolean(Number(news.fixed)),
    };
  };
  getProgDocs = async (id) => {
    const docs = await this.getResource(`prog_docs/${id}`);
    if (docs['error']) {
      return docs;
    }
    return docs.map((doc) => {
      return this._transformDoc(doc);
    });
  };
  _transformDoc = (doc) => {
    return Object.assign({}, doc, {url: this._docBase + doc.url});
  };
  getProgComments = async (id) => {
    const comments = await this.getResource(`prog_comments/${id}`);
    if (comments['error']) {
      return comments;
    }
    return comments.map((comment) => {
      return this._transformComment(comment);
    });
  };
  _transformComment = (comment) => {
    return comment;
  };
  getTeacherImageUrl = () => {};
  getProgDocUrl = () => {};
  getProgTeacherList = async (code) => {
    const progTeacherList = await this.getResource(`prog_teachers/${code}`);
    return progTeacherList;
  };
  getAllSimpleTeachers = async () => {
    const teachers = await this.getResource(`teachers_simple`);
    await console.log('getAllSimpleTeachers', teachers);
    return teachers.results
      .map((teacher) => {
        return this._transformSimpleTeacher(teacher);
      })
      .sort(this.sortTeachers);
  };
  _transformSimpleTeacher = (teacher) => {
    return {
      id: teacher.id,
      name: teacher.name,
      sname: teacher.sname,
      fname: teacher.fname,
      photo: this._docBase + teacher.photo,
      birthdate: teacher.birthdate,
    };
  };
  getAllTeachers = async () => {
    const teachers = await this.getResource(`teachers`);

    return teachers.results
      .map((teacher) => {
        return this._transformTeacher(teacher);
      })
      .sort(this.sortTeachers);
  };
  getAllTeachersTable = async () => {
    const teachers = await this.getResource(`teachers/list`);
    return teachers.results
      .map(this._transformTeacherInListTable)
      .sort(this.sortTeachers);
  };
  getAdminTeacher = async (id) => {
    const teacher = await this.getOneTeacher(id);
    const visits = await this.getTeacherVisits(id);
    const user_info = await this.getOneUser(teacher.usr_id);
    const reports = await this.getTeacherReports(teacher.id);
    const progs = await this.getTeacherIDProgs(teacher.id);
    const addresses = await this.getTeacherAddresses(teacher.id);
    // const addresses = null;
    // await console.log('ADDRESSES', addresses);
    return Object.assign({}, teacher, {
      visits: visits,
      user_info: user_info,
      reports: reports,
      progs: progs,
      addresses: addresses ?? [],
    });
  };
  /* getOneTeacher = async (id) => {
    const teacher = await this.getResource(`teachers/${id}`);
    return this._transformTeacher(teacher);
  }; */

  _transformTeacherInListTable = (teacher) => {
    return {
      id: teacher.id,
      name: teacher.name,
      sname: teacher.sname,
      fname: teacher.fname,
      photo: this._docBase + teacher.photo,
      // birthdate: teacher.birthdate,
      phone: teacher.phone,
      work_email: teacher.work_email,
      progs: teacher.progs.map(this._transformTeacherProgs),
      addresses: teacher.addresses,
    };
  };
  _transformTeacher = (teacher) => {
    // console.log(teacher);
    return {
      id: teacher.id,
      usr_id: teacher.usr_id,
      name: teacher.name,
      sname: teacher.sname,
      fname: teacher.fname,
      photo: this._docBase + teacher.photo,
      birthdate: teacher.birthdate,
      personal_address: teacher.personal_address,
      phone: teacher.phone,
      work_email: teacher.work_email,
      personal_email: teacher.personal_email,
      extra: teacher.extra,
      active: Boolean(+teacher.active),
    };
  };
  getAllAddresses = async () => {
    const addresses = await this.getResource(`addresses`);
    return addresses.error || addresses.errorServer === 404 ? [] : addresses;
  };
  sortTeachers = (a, b) => {
    if (a.fname < b.fname) {
      return -1;
    }
    if (a.fname > b.fname) {
      return 1;
    }
    if (a.fname === b.fname) {
      return 0;
    }
  };
  getTeachersSimple = async () => {
    // console.log('getTeachersSimple');
    const teachers = await this.getResource(`teachers_simple`);
    return teachers.sort(this.sortTeachers);
  };
  getProgsSimple = async () => {
    const progs = await this.getResource(`progs_simple`);
    return progs.map(this._transformSimpleProg);
  };
  getOneTeacherProgsSimple = async (tid) => {
    const _progs = await this.getResource(`progs_simple/${tid}`);
    const progs =
      _progs.error || _progs.errorServer === 404
        ? []
        : _progs.map(this._transformSimpleProg);
    await console.log('getTeachersSimple', progs);
    return progs;
  };
  _transformSimpleProg = (prog) => {
    const {id, code, prog_code, abbr, name} = prog;
    return {
      id,
      code: code ?? prog_code,
      abbr,
      title: name,
    };
  };
  getAllLocalActs = async () => {
    const acts = await this.getResource(`local_acts`);
    return acts.results.map(this._transformLocalAct);
  };
  _transformLocalAct = ({id, name, url}) => {
    return {
      id,
      name,
      url: this._docBase + url,
    };
  };
  getTeacherVisits = async (id) => {
    const _visits = await this.getResource(`visits/${id}`);
    const visits =
      _visits.error || _visits.errorServer === 404
        ? []
        : _visits.map(this._transformVisit);
    return visits;
  };
  getDateNum = (n) => {
    return n <= 9 ? `0${n}` : n;
  };
  getDuration = (end, start) => {
    if (end == 0) {
      return 0;
    }
    const all_sec = end - start;
    const sec = all_sec % 60;
    const all_min = Math.floor(all_sec / 60);
    if (!all_min) {
      return `00.${this.getDateNum(sec)}`;
    }
    const min = all_min > 59 ? all_min % 60 : all_min;
    const h = Math.floor(all_min / 60);
    if (!h) {
      return `${this.getDateNum(min)}:${this.getDateNum(sec)}`;
    }
    return `${this.getDateNum(h)}:${this.getDateNum(min)}:${this.getDateNum(
      sec
    )}`;
  };

  _transformVisit = (visit) => {
    const {id, teacher_id, start, end} = visit;
    const endDate = new Date(end * 1000);
    const d = this.getDateNum(endDate.getDate());
    const m = this.getDateNum(endDate.getMonth() + 1);
    const y = endDate.getFullYear();
    const date = `${d}.${m}.${y}`;
    const dur = this.getDuration(end, start);
    return {
      id: id,
      teacher_id: teacher_id,
      date: date,
      duration: dur,
    };
  };
  getOneUser = async (user_id) => {
    const user = await this.getResource(`users/${user_id}`);
    return user;
  };
  getTeacherReports = async (teacher_id) => {
    const _reports = await this.getResource(`reports/${teacher_id}`);
    const reports =
      _reports.error || _reports.errorServer === 404 ? [] : _reports;
    return reports.map(this._transformReport);
  };
  _transformReport = ({id, teacher_id, name, url}) => {
    return {
      id,
      teacher_id,
      name,
      url: `${this._docBase + url}`,
    };
  };
  getAllTeacherProgs = async () => {
    const _progs = await this.getResource(`teacher_progs`);
    const progs = _progs.error || _progs.errorServer === 404 ? [] : _progs;
    return progs.map(this._transformTeacherProgs);
  };
  /*2 функции выяснить где ещё применяется первая , вторая для поиска программ по id*/
  getTeacherProgs = async (teacher_id) => {
    const _progs = await this.getResource(`teacher_progs/${teacher_id}`);
    const progs = _progs.error || _progs.errorServer === 404 ? [] : _progs;
    return progs.map(this._transformTeacherProgs);
  };
  _transformTeacherProgs = (prog) => {
    // console.log('_transformTeacherProgs', prog);
    return `${prog.prog_code}-${prog.abbr}`;
  };

  getTeacherIDProgs = async (teacher_id) => {
    const _progs = await this.getResource(`teacher_progs/${teacher_id}`);
    const progs = _progs.error || _progs.errorServer === 404 ? [] : _progs;
    return progs.map(this._transformTeacherIDProgs);
  };
  _transformTeacherIDProgs = (prog) => {
    console.log('_transformTeacherEditProgs', prog);
    return prog;
  };

  _transformMissions = (missions) => {
    const fullMissions = [];

    for (let i = 0; i < missions.length; i++) {
      const mission = missions[i];
      let ids = mission['teacher_id'].split(', ');
      console.log('ids', ids);

      if (ids.length === 1) {
        const new_mission = Object.assign({}, mission, {
          is_finished: Boolean(Number(mission.is_finished)),
          teacher_ready: Boolean(Number(mission.teacher_ready)),
        });
        console.log('new_mission', new_mission);
        fullMissions.push(new_mission);
        continue;
      }

      const teacher_ready_arr = mission['teacher_ready'].split(', ');
      const is_finished_arr = mission['is_finished'].split(', ');
      const mission_teacher_ids = mission['mission_teacher_id'].split(', ');

      if (ids.length > 1) {
        const new_missions = ids.map((id, i) => {
          return Object.assign({}, mission, {
            teacher_id: id,
            teacher_ready: Boolean(Number(teacher_ready_arr[i])),
            is_finished: Boolean(Number(is_finished_arr[i])),
            mission_teacher_id: mission_teacher_ids[i],
          });
        });
        console.log('new_missions', new_missions);
        fullMissions.push(...new_missions);
      }
    }
    return fullMissions;
  };

  getAllMissions = async () => {
    const res = await this.getResource(`missions`);
    const teachers = await this.getAllTeachersTable();
    const addresses = await this.getAllAddresses();
    const progs = await this.getProgsSimple();
    await console.log(res);
    // const missions = res.results.map(this._transformOneMission);
    const missions = this._transformMissions(
      res.results.map(this._transformOneMission)
    );

    return Object.assign(
      {},
      {
        missions: missions,
        teachers: teachers,
        addresses: addresses,
        progs: progs,
      }
    );
  };

  /*  getAllMissions = async () => {
    const res = await this.getResource(`missions`);
    const teachers = await this.getAllSimpleTeachers();
    const missions = res.results.map(this._transformOneMission);

    return Object.assign(
      {},
      {
        missions: missions,
        teachers: teachers,
      }
    );
  }; */
  _transformOneMission = (mission) => {
    console.log(
      'mission.created',
      Number(mission.created),
      fromMillisecToDate(Number(mission.created))
    );
    const fullDate = serverDateToFullDate(
      fromMillisecToDate(Number(mission.created))
    );
    const date = fullDate[1].split('-').reverse().join('.');
    const deadline = mission.deadline.split('-').reverse().join('.');
    return {
      id: mission.id,
      teacher_id: mission.teacher_id,
      subject: mission.subject,
      body: mission.body,
      deadline: deadline,
      // deadline: mission.deadline.replace(/-/g, '.'),
      // created_date:  fullDate[1].replace(/-/g, '.'),
      created_date: date,
      created_time: fullDate[2],
      teacher_abbr: mission.teacher_abbr,
      admin_id: mission.admin_id,
      ready: Boolean(Number(mission.ready)),
      teacher_ready: mission.teacher_ready,
      is_finished: mission.is_finished,
      mission_teacher_id: mission.mission_teacher_id,
    };
  };
  getLkTabsInfo = async (teacher_id) => {
    const missions = await this.getTeacherMissions(teacher_id);
    const reports = await this.getTeacherReports(teacher_id);
    return Object.assign(
      {},
      {
        reports: reports,
        missions: missions,
      }
    );
  };
  getTeacherMissions = async (teacher_id) => {
    const _missions = await this.getResource(`teacher_missions/${teacher_id}`);
    const missions =
      _missions.error || _missions.errorServer === 404 ? [] : _missions;
    await console.log('getTeacherMissions', missions);
    await console.log(
      'getTeacherMissions',
      this._transformMissions(missions.map(this._transformOneMission))
    );
    // return missions.map(this._transformOneMission);
    return this._transformMissions(missions.map(this._transformOneMission));
  };

  getAllHolidays = async () => {
    const holidays = await this.getResource(`holidays`);
    return holidays.results;
  };
  getAllSchoolHolidays = async () => {
    const vacs = await this.getResource(`school_holidays`);
    // await console.log(vacs);
    return vacs.results;
  };

  getAllAddresses = async () => {
    const addresses = await this.getResource(`addresses`);
    return addresses.results;
  };
  getTeacherAddresses = async (teacher_id) => {
    const addresses = await this.getResource(`teacher_address/${teacher_id}`);
    await console.log(
      'AAADDDRESSES FROM SERVER',
      this._cleanEmptyAddressesFromServer(this._cleanAddressError(addresses))
    );
    return this._cleanEmptyAddressesFromServer(
      this._cleanAddressError(addresses)
    );
  };
  _cleanEmptyAddressesFromServer = (addresses) => {
    console.log('_cleanEmptyAddressesFromServer', addresses);
    if (
      addresses.some((address) => {
        return address.id === null;
      })
    ) {
      return [];
    }
    return addresses;
  };
  _cleanAddressError = (addresses) => {
    console.log(
      '_cleanAddresseError',
      addresses.error,
      Boolean(addresses.error)
    );
    if (addresses.error || addresses.errorServer === 404) {
      addresses = [];
    }
    return addresses;
  };
  // ????
  getTeacherLkInfo = async (tid) => {
    const teacher = await this.getOneTeacher(tid);
    const reports = await this.getTeacherReports(tid);
    const missions = await this.getTeacherMissions(tid);
    return Object.assign({}, teacher, {
      reports: reports,
      missions: missions,
    });
  };
  getAllTimetables = async () => {
    const timetables = await this.getResource('timetables');
    return timetables.results.map(this._transformDocumentType);
  };
  getAllGradDocs = async () => {
    const gradDocs = await this.getResource('graduation_docs');
    await console.log(gradDocs);
    return gradDocs.results.map(this._transformDocumentType);
  };
  getAllMainDocs = async () => {
    const mainDocs = await this.getResource('main_docs');
    await console.log(mainDocs);
    return mainDocs.results.map(this._transformDocumentType);
  };
  _transformDocumentType = (doc) => {
    return Object.assign({}, doc, {
      url: this._docBase + doc.url,
    });
  };
  getManagementEmails = async () => {
    const emails = await this.getResource('contact_emails');
    await console.log(emails);
    return emails.results;
  };
}
