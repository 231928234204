import React from 'react';
import Nav from '../nav';

import './header.css';

const Header = (props) => {
  return (
    <header className="user-header user-header--admin ">
      <div className="container user-header__inner">
        <Nav />
      </div>
    </header>
  );
};
export default Header;
