import React, {useContext} from 'react';
import {Route} from 'react-router-dom';
import {CurrentUserContext} from './contexts/currentUser';

export const ProtectedComponent = (props) => {
  console.log(props);
  const Component = props['component'];
  const [currentUserState, setCurrentUserState] = useContext(
    CurrentUserContext
  );

  const {isLoggedIn, admin, currentUser} = currentUserState;
  console.log(
    'isLoggedIn, admin, currentUser — ',
    isLoggedIn,
    admin,
    currentUser
  );

  // return isLoggedIn ? <Component {...rest} /> : null;
  return isLoggedIn ? <Component /> : null;
};
