import React, {useContext} from 'react';
import {CurrentUserContext} from '../contexts/currentUser';
import {withRouter} from 'react-router-dom';

import './nav-mob.css';
import {Link} from 'react-router-dom';

const mobNavItems = [
  {
    path: '/news',
    render: () => {
      return (
        <React.Fragment>
          <i className="message-icon"></i>
          <span>Новости</span>
        </React.Fragment>
      );
    },
  },
  {
    path: '/lk/',
    render: () => {
      return (
        <React.Fragment>
          <i className="teachers-icon"></i>
          <span>Поручения</span>
        </React.Fragment>
      );
    },
  },
  {
    path: '/proglist',
    render: () => {
      return (
        <React.Fragment>
          <i className="message-checked-icon"></i>
          <span>Программы</span>
        </React.Fragment>
      );
    },
  },
];

const NavMob = (props) => {
  const [currentUserState] = useContext(CurrentUserContext);

  const {isLoggedIn, admin, currentUser} = currentUserState;

  const {location} = props;

  const renderItems = (items) => {
    return items.map(({path, render}, i) => {
      const isActive =
        path === location.pathname
          ? 'admin-mob-menu__item admin-mob-menu__item--active'
          : 'admin-mob-menu__item';
      return (
        <li className={isActive} key={i}>
          <Link to={path} className="admin-mob-menu__link btn-icon">
            {render()}
          </Link>
        </li>
      );
    });
  };
  const menuItems = renderItems(mobNavItems);
  return (
    <div className="admin-mob-menu">
      <ul className="admin-mob-menu__list">
        {menuItems}
        <li className="admin-mob-menu__item">
          <Link to="/logout" className="admin-mob-menu__link btn-icon">
            <i className="sign-out-icon"></i>
            <span>Выход</span>
          </Link>
          {/* <a className="admin-mob-menu__link btn-icon" href="#">
            <i className="sign-out-icon"></i>
            <span>Выход</span>
          </a> */}
        </li>
      </ul>
    </div>
  );
};
export default withRouter(NavMob);
