import {useEffect, useState} from 'react';

const useLocalStorage = (key, initialValue = '') => {
  const [value, setValue] = useState(() => {
    const v = JSON.parse(localStorage.getItem('userTlk')) || {};
    const va = v[key] || initialValue;
    // console.log('localStorage.getItem("userTlk")', va);
    return va;
  });

  useEffect(() => {
    // const val = value || '';
    const all = JSON.parse(localStorage.getItem('userTlk')) || {};
    // console.log('all', all);
    // console.log('value', value);
    all[key] = value;
    // console.log('all 2', all, JSON.stringify(all));
    localStorage.setItem('userTlk', JSON.stringify(all));
    // console.log('localStorage 2 userTlk', localStorage.getItem('userTlk'));
  }, [value, key]);

  return [value, setValue];
};
export default useLocalStorage;
