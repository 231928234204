import React, {Component} from 'react';
import BigErrorIndicator from '../big-error-indicator';

import './big-error-boundary.css';

export default class BigErrorBoundary extends Component {
  state = {
    hasError: false,
  };
  componentDidCatch() {
    this.setState({
      hasError: true,
    });
  }
  render() {
    if (this.state.hasError) {
      return <BigErrorIndicator />;
    }
    return this.props.children;
  }
}
