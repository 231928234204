import React from 'react';
import ReactDOM from 'react-dom';

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./fonts/Oswald-Medium.ttf";
import './index.css';

// import "./css/style.css";

import App from './components/app';

/* ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
); */
ReactDOM.render(<App />,document.getElementById('root'));