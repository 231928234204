export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('userTlk'));

  if (user && user.token) {
    return {
      'Auth-Key': 'tlkapi',
      'Client-Service': 'frontend-tlkprorisu',
      Authorization: 'TlkToken ' + user.token,
    };
  } else {
    return {};
  }
}
