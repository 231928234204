import React, {useContext} from 'react';
import {CurrentUserContext} from '../../components/contexts/currentUser';
import {withRouter} from 'react-router-dom';

import './nav.css';
import {Link} from 'react-router-dom';

const NavItems = [
  {
    path: '/news',
    render: () => {
      return 'Учебная часть';
    },
  },
  {
    path: '/lk/',
    render: () => {
      return (
        <React.Fragment>
          <span className="admin-menu__italic">Личный</span>
          кабинет
        </React.Fragment>
      );
    },
  },
  {
    path: '/proglist',
    render: () => {
      return (
        <React.Fragment>
          <span className="admin-menu__italic">Учебные</span>
          программы
        </React.Fragment>
      );
    },
  },
];

const Nav = (props) => {
  const [currentUserState] = useContext(CurrentUserContext);

  const {isLoggedIn, admin, currentUser} = currentUserState;
  const {location} = props;
  const enterItem = isLoggedIn ? (
    <li className="admin-menu__item fw800">
      <Link to="/logout" className="admin-menu__link">
        <span>Покинуть</span>&nbsp; сайт
      </Link>
    </li>
  ) : (
    <li className="admin-menu__item fw800">
      <Link to="/login" className="admin-menu__link fw800">
        Войти
      </Link>
    </li>
  );

  const renderNavItems = (items) => {
    return items.map(({path, render}, i) => {
      const isActive =
        path === location.pathname
          ? 'admin-menu__item admin-menu__item--active'
          : 'admin-menu__item';
      return (
        <li key={i} className={isActive}>
          <Link to={path} className="admin-menu__link fw800">
            {render()}
          </Link>
        </li>
      );
    });
  };
  const menuItems = renderNavItems(NavItems);

  return (
    <div className="admin-menu">
      <ul className="admin-menu__list">
        {menuItems}

        <li className="admin-menu__item to992hidden">
          <a className="admin-menu__link" href="https://prorisunok.ru/detyam">
            <span className="admin-menu__italic">Развитие</span>
            дошкольника
          </a>
        </li>

        <li className="admin-menu__item to992hidden">
          <a
            className="admin-menu__link"
            href="https://prorisunok.ru/shkolnikam"
          >
            <span className="admin-menu__italic">Обучение</span>
            школьника
          </a>
        </li>

        <li className="admin-menu__item to992hidden">
          <a className="admin-menu__link" href="https://prorisunok.ru/vzroslym">
            <span className="admin-menu__italic">Курсы для</span>
            взрослых
          </a>
        </li>

        {enterItem}
      </ul>
    </div>
  );
};
export default withRouter(Nav);
