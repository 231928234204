import React, {Component} from "react";

import "./big-error-indicator.css";

export default class BigErrorIndicator extends Component {
  
  render() {
    return (
      <div className="big-error-wrapper">
        <div className="big-error-indicator">
          Ой ...
        </div>
      </div>
      
    );
  }
}