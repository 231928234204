import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {CurrentUserContext} from './contexts/currentUser';

export const ProtectedRoute = ({component: Component, ...rest}) => {
  const [currentUserState] = useContext(CurrentUserContext);

  const {isLoggedIn, admin, currentUser} = currentUserState;

  /* console.log(
    'isLoggedIn, admin, currentUser Protected — ',
    isLoggedIn,
    admin,
    currentUser
  ); */
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                // isExact: true,
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
